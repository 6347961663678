import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import DropDown from '../../components/dropDown';
import FormComponent from '../../components/form';
import {
  lotteryRepairFormConfig,
  gamingRepairFormConfig,
  incorrectCaseFormConfig,
  fstOvertimeFormConfig,
  fieldRepairFormConfig,
  californiaPTLFormConfig,
  forwardToSiteFormConfig,
  gnieUpdateRequestFormConfig,
  retailerInfoChangeFormConfig,
  washingtonRetailerTrainingRequestFormConfig,
  consumableSupplyFormConfig,
  nyKeyRequestFormConfig,
  dispatchStatusChangeFormConfig,
  nrcDowntimeFormConfig,
  stolenAndMissingInstantTicketFormConfig,
  leewardIslandCreditLogFormConfig,
  michiganIncidentLogFormConfig,
  nyAMLogFormConfig,
  riCreditLogFormConfig,
} from '../../utils/formConfigs';
import NavigationBar from '../../components/navigationBar';
import { formMenuData } from '../../utils/menuData';
import { LanguageContext } from '../../context/languageContext';
import Cookies from 'js-cookie';
import axios from 'axios';

const Forms = () => {
  // Query Params
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [selectedCountry, setSelectedCountry] = useState(
    queryParams.get('country') || 'United States'
  );
  const [selectedTeam, setSelectedTeam] = useState(
    queryParams.get('team') || ''
  );
  const [selectedFormType, setSelectedFormType] = useState(
    queryParams.get('form') || ''
  );

  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedCountry) params.set('country', selectedCountry);
    if (selectedTeam) params.set('team', selectedTeam);
    if (selectedFormType) params.set('report', selectedFormType);
    navigate({ search: params.toString() });
  }, [selectedCountry, selectedTeam, selectedFormType, navigate]);

  const { translations } = useContext(LanguageContext);
  const [showForm, setShowForm] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSecondTimeReturn, setIsSecondTimeReturn] = useState('Yes');
  const [isTech, setIsTech] = useState('yes'); // Ensure initial state is 'yes' or 'no'
  const [isJurisdiction, setIsJurisdiction] = useState('Indiana'); // Ensure initial JID state is Indiana
  const [openCase, setOpenCase] = useState('yes'); // Ensure initial state is 'yes' or 'no'
  const [updatePhone, setUpdatePhone] = useState('no'); // Ensure initial state is 'yes' or 'no'
  const [requestedPasscode, setRequestedPasscode] = useState('no'); // Ensure initial state is 'yes' or 'no'
  const [updateStoreHours, setUpdateStoreHours] = useState('no'); // Ensure initial state is 'yes' or 'no'
  const [supRequested, setSupRequested] = useState('no'); // Ensure initial state is 'yes' or 'no'
  const [userDetails, setUserDetails] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchUserDetails = async () => {
      const usersEndpoint = process.env.REACT_APP_ENDPOINT_USERS;
      const token = Cookies.get('token');
      if (!token) {
        console.error('Access token is not available.');
        return;
      }

      try {
        const response = await axios.get(`${usersEndpoint}/user-details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          setUserDetails(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        setUserDetails({});
      }
    };

    fetchUserDetails();
  }, []);

  const teams =
    formMenuData(translations).find(data => data.country === selectedCountry)
      ?.teams || [];
  const forms = teams.find(team => team.name === selectedTeam)?.forms || [];

  const handleCountryChange = event => {
    setSelectedCountry(event.target.value);
    setSelectedTeam('');
    setSelectedFormType('');
    setShowForm(false);
  };

  const handleTeamChange = event => {
    setSelectedTeam(event.target.value);
    setSelectedFormType('');
    setShowForm(false);
  };

  const handleFormTypeChange = event => {
    setSelectedFormType(event.target.value);
    setShowForm(false);
  };

  const handleShowFormClick = () => {
    if (selectedFormType) setShowForm(true);
  };

  const formConfigs = {
    FSTOvertimeForm: fstOvertimeFormConfig(translations),
    IncorrectCaseForm: incorrectCaseFormConfig(translations),
    FieldRepairForm: fieldRepairFormConfig(translations),
    LotteryRepairWorklogForm: lotteryRepairFormConfig(translations),
    GamingRepairWorklogForm: gamingRepairFormConfig(translations),
    CaliforniaPTLForm: californiaPTLFormConfig,
    ForwardToSiteForm: forwardToSiteFormConfig,
    GNIEUpdateRequestForm: gnieUpdateRequestFormConfig,
    RetailerInfoChangeForm: retailerInfoChangeFormConfig,
    WARetailerTrainingRequestForm: washingtonRetailerTrainingRequestFormConfig,
    ConsumableSupplyForm: consumableSupplyFormConfig,
    NYKeyRequestForm: nyKeyRequestFormConfig,
    DispatchStatusChangeRequestForm: dispatchStatusChangeFormConfig,
    NRCDowntimeForm: nrcDowntimeFormConfig,
    StolenAndMissingInstantTicketForm: stolenAndMissingInstantTicketFormConfig,
    LeewardIslandCreditLogForm: leewardIslandCreditLogFormConfig,
    MichiganIncidentLogForm: michiganIncidentLogFormConfig,
    NYAMLogForm: nyAMLogFormConfig,
    RICreditLogForm: riCreditLogFormConfig,
  };

  const handleSecondTimeReturnChange = value => {
    setIsSecondTimeReturn(value);
  };

  const handleIsTechChange = value => {
    setIsTech(value);
    // console.log(`isTech changed to ${value}`);
  };

  const handleIsJIDChange = value => {
    setIsJurisdiction(value);
    // console.log(`isJurisdiction changed to ${value}`);
  };

  const handleOpenCaseChange = value => {
    setOpenCase(value);
    // console.log(`openCase changed to ${value}`);
  };

  const handleUpdatePhone = value => {
    setUpdatePhone(value);
    // console.log(`setUpdatePhone ${value}`);
  };

  const handleUpdateStoreHours = value => {
    setUpdateStoreHours(value);
    // console.log(`openCase changed to ${value}`);
  };

  const handleRequestedPasscode = value => {
    setRequestedPasscode(value);
    // console.log(`openCase changed to ${value}`);
  };

  const handleSupervisorRequested = value => {
    setSupRequested(value);
  };

  const renderForm = () => {
    // If the form should not be shown, return null to avoid rendering it
    if (!showForm) return null;

    // Find the configuration for the form based on the selected form type
    // Find the configuration for the form based on the selected form type
    const formConfig = forms.find(
      form => form.componentKey === selectedFormType
    );

    // Find the configuration for the form based on the selected form type
    if (formConfig?.componentKey && formConfigs[formConfig.componentKey]) {
      // Clone the form configuration to apply dynamic changes
      // Clone the form configuration to apply dynamic changes
      const dynamicFormConfig = { ...formConfigs[formConfig.componentKey] };

      // Special condition for the 'LotteryRepairWorklogForm' - filter out 'returnDate' if 'isSecondTimeReturn' is not 'Yes'
      if (formConfig.componentKey === 'LotteryRepairWorklogForm') {
        dynamicFormConfig.fields = dynamicFormConfig.fields.filter(
          field => field.name !== 'returnDate' || isSecondTimeReturn === 'Yes'
        );
      }

      if (formConfig.componentKey === 'FSTOvertimeForm') {
        dynamicFormConfig.fields = dynamicFormConfig.fields.filter(field => {
          if (field.name === 'fstNumber' && isTech !== 'yes') return false;
          if (field.name === 'caseNumber' && openCase !== 'yes') return false;
          return true;
        });
      }

      if (formConfig.componentKey === 'ForwardToSiteForm') {
        dynamicFormConfig.fields = dynamicFormConfig.fields.filter(field => {
          if (
            field.name === 'retailerComplaint' &&
            isJurisdiction !== 'Indiana'
          )
            return false;
          if (
            field.name === 'communicationsType' &&
            isJurisdiction !== 'Indiana'
          )
            return false;
          if (
            (field.name === 'messageToSup' ||
              field.name === 'contactName' ||
              field.name === 'callbackNumber') &&
            supRequested !== 'yes'
          )
            return false;
          if (
            (field.name === 'messageToSite' ||
              field.name === 'gnieArticleNumber') &&
            supRequested === 'yes'
          )
            return false;

          ///////////////////////////////////////
          // California
          ///////////////////////////////////////

          if (
            field.name === 'openCase' ||
            field.name === 'fstAssigned' ||
            field.name === 'gnieArticleNumber' ||
            field.name === 'caseNumber'
          ) {
            if (isJurisdiction === 'California') {
              return false;
            } else {
              return true;
            }
          }

          ///////////////////////////////////////
          // Connecticut
          ///////////////////////////////////////

          if (field.name === 'thresholdExceeded') {
            if (isJurisdiction !== 'Connecticut') {
              return false;
            } else {
              return true;
            }
          }

          return true;
        });
      }

      if (formConfig.componentKey === 'FieldRepairForm') {
        dynamicFormConfig.fields = dynamicFormConfig.fields.filter(field => {
          if (field.name === 'caseNumber' && openCase !== 'yes') return false;
          return true;
        });
      }

      if (formConfig.componentKey === 'CaliforniaPTLForm') {
        dynamicFormConfig.fields = dynamicFormConfig.fields.filter(field => {
          if (field.name === 'newPhone' && updatePhone !== 'yes') return false;
          if (
            (field.name === 'sundayOpen' ||
              field.name === 'sundayClose' ||
              field.name === 'mondayOpen' ||
              field.name === 'mondayClose' ||
              field.name === 'tuesdayOpen' ||
              field.name === 'tuesdayClose' ||
              field.name === 'wednesdayOpen' ||
              field.name === 'wednesdayClose' ||
              field.name === 'thursdayOpen' ||
              field.name === 'thursdayClose' ||
              field.name === 'fridayOpen' ||
              field.name === 'fridayClose' ||
              field.name === 'saturdayOpen' ||
              field.name === 'saturdayClose') &&
            updateStoreHours !== 'yes'
          )
            return false;
          return true;
        });
      }

      if (formConfig.componentKey === 'RetailerInfoChangeForm') {
        dynamicFormConfig.fields = dynamicFormConfig.fields.filter(field => {
          if (
            field.name === 'requestedPasscode' ||
            (field.name === 'updateStoreHours' && isJurisdiction === 'Texas')
          )
            return false;
          if (
            (field.name === 'oldPasscode' ||
              field.name === 'newPasscode' ||
              field.name === 'requestedBy' ||
              field.name === 'managerPasscode') &&
            requestedPasscode !== 'yes'
          ) {
            return false;
          }
          if (
            (field.name === 'sundayOpen' ||
              field.name === 'sundayClose' ||
              field.name === 'mondayOpen' ||
              field.name === 'mondayClose' ||
              field.name === 'tuesdayOpen' ||
              field.name === 'tuesdayClose' ||
              field.name === 'wednesdayOpen' ||
              field.name === 'wednesdayClose' ||
              field.name === 'thursdayOpen' ||
              field.name === 'thursdayClose' ||
              field.name === 'fridayOpen' ||
              field.name === 'fridayClose' ||
              field.name === 'saturdayOpen' ||
              field.name === 'saturdayClose') &&
            updateStoreHours !== 'yes'
          )
            return false;
          if (
            (field.name === 'newPhone' || field.name === 'oldPhone') &&
            updatePhone !== 'yes'
          )
            return false;
          return true;
        });
      }

      if (formConfig.componentKey === 'ConsumableSupplyForm') {
        dynamicFormConfig.fields = dynamicFormConfig.fields.filter(field => {
          ///////////////////////////////////////
          // Multi JID Fields.
          ///////////////////////////////////////

          if (field.name === 'adjustmentForms') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Kentucky'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'cash3') {
            if (
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Mississippi' ||
              isJurisdiction === 'Tennessee'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'cash4') {
            if (
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Mississippi' ||
              isJurisdiction === 'Tennessee'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'cash4Life') {
            if (
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Missouri' ||
              isJurisdiction === 'New Jersey' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'Tennessee'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'cash5') {
            if (
              isJurisdiction === 'Connecticut' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'North Carolina'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'cashPop') {
            if (
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Michigan' ||
              isJurisdiction === 'Mississippi' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'South Carolina' ||
              isJurisdiction === 'Washington'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'claimForms') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'New York'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'daily3') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Michigan'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'daily4') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Michigan'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'dailyKeno') {
            if (
              isJurisdiction === 'Michigan' ||
              isJurisdiction === 'Washington'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'envelopes') {
            if (
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'Wisconsin'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'fantasy5') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Michigan'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'itvmRolls') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Michigan' ||
              isJurisdiction === 'New Jersey' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'Tennessee' ||
              isJurisdiction === 'Wisconsin'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'keno') {
            if (
              isJurisdiction === 'Connecticut' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Washington'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'lotto') {
            if (
              isJurisdiction === 'Connecticut' ||
              isJurisdiction === 'Colorado' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Missouri' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'Washington'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'lottoAmerica') {
            if (
              isJurisdiction === 'Minnesota' ||
              isJurisdiction === 'Mississippi' ||
              isJurisdiction === 'Tennessee'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'luckyForLife') {
            if (
              isJurisdiction === 'Colorado' ||
              isJurisdiction === 'Connecticut' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Michigan' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'South Carolina'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'megaMillions') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Colorado' ||
              isJurisdiction === 'Connecticut' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Michigan' ||
              isJurisdiction === 'Minnesota' ||
              isJurisdiction === 'Mississippi' ||
              isJurisdiction === 'Missouri' ||
              isJurisdiction === 'New Jersey' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'South Carolina' ||
              isJurisdiction === 'Tennessee' ||
              isJurisdiction === 'Washington' ||
              isJurisdiction === 'Wisconsin'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'pencils') {
            if (
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Tennessee'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'perforatedTape') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'Wisconsin'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'pick3') {
            if (
              isJurisdiction === 'Colorado' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Minnesota' ||
              isJurisdiction === 'Missouri' ||
              isJurisdiction === 'New Jersey' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'South Carolina' ||
              isJurisdiction === 'Washington' ||
              isJurisdiction === 'Wisconsin'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'pick4') {
            if (
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Missouri' ||
              isJurisdiction === 'New Jersey' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'South Carolina' ||
              isJurisdiction === 'Wisconsin'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'powerball') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Colorado' ||
              isJurisdiction === 'Connecticut' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Michigan' ||
              isJurisdiction === 'Minnesota' ||
              isJurisdiction === 'Mississippi' ||
              isJurisdiction === 'Missouri' ||
              isJurisdiction === 'New Jersey' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'South Carolina' ||
              isJurisdiction === 'Tennessee' ||
              isJurisdiction === 'Washington' ||
              isJurisdiction === 'Wisconsin'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'superLottoPlus') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Michigan'
            ) {
              return true;
            } else {
              return false;
            }
          }

          if (field.name === 'ticketStock') {
            if (
              isJurisdiction === 'California' ||
              isJurisdiction === 'Colorado' ||
              isJurisdiction === 'Florida' ||
              isJurisdiction === 'Georgia' ||
              isJurisdiction === 'Indiana' ||
              isJurisdiction === 'Kentucky' ||
              isJurisdiction === 'Michigan' ||
              isJurisdiction === 'Minnesota' ||
              isJurisdiction === 'Mississippi' ||
              isJurisdiction === 'Missouri' ||
              isJurisdiction === 'New Jersey' ||
              isJurisdiction === 'New York' ||
              isJurisdiction === 'North Carolina' ||
              isJurisdiction === 'South Carolina' ||
              isJurisdiction === 'Tennessee' ||
              isJurisdiction === 'Washington'
            ) {
              return true;
            } else {
              return false;
            }
          }

          ///////////////////////////////////////
          // California
          ///////////////////////////////////////
          if (
            (field.name === 'dailyDerby' || field.name === 'hotSpot') &&
            isJurisdiction !== 'California'
          )
            return false;

          ///////////////////////////////////////
          // Colorado
          ///////////////////////////////////////
          if (field.name === 'cash5EzMatch' && isJurisdiction !== 'Colorado')
            return false;

          ///////////////////////////////////////
          // Connecticut
          ///////////////////////////////////////
          if (
            (field.name === 'play3' ||
              field.name === 'play4' ||
              field.name === 'ultraTicketStock') &&
            isJurisdiction !== 'Connecticut'
          )
            return false;

          ///////////////////////////////////////
          // Florida
          ///////////////////////////////////////
          if (
            (field.name === 'lottoExtra' ||
              field.name === 'pick2' ||
              field.name === 'pick5' ||
              field.name === 'tripleJackpotPlay') &&
            isJurisdiction !== 'Florida'
          )
            return false;

          ///////////////////////////////////////
          // Georgia
          ///////////////////////////////////////
          if (
            (field.name === 'gaCashPop' ||
              field.name === 'georgia5' ||
              field.name === 'kenoPencils') &&
            isJurisdiction !== 'Georgia'
          )
            return false;

          ///////////////////////////////////////
          // Indiana
          ///////////////////////////////////////
          if (field.name === 'quickDraw' && isJurisdiction !== 'Indiana')
            return false;

          ///////////////////////////////////////
          // Kentucky
          ///////////////////////////////////////
          if (
            (field.name === 'cashball' ||
              field.name === 'kentucky5' ||
              field.name === 'quickBucks') &&
            isJurisdiction !== 'Kentucky'
          )
            return false;

          ///////////////////////////////////////
          // Michigan
          ///////////////////////////////////////
          if (
            (field.name === 'classicLotto47' ||
              field.name === 'clubKenoJack' ||
              field.name === 'instantTicketTrackingForms') &&
            isJurisdiction !== 'Michigan'
          )
            return false;

          ///////////////////////////////////////
          // Minnesota
          ///////////////////////////////////////
          if (
            (field.name === 'gopher5' || field.name === 'northstar5') &&
            isJurisdiction !== 'Minnesota'
          )
            return false;

          ///////////////////////////////////////
          // Mississippi
          ///////////////////////////////////////
          if (field.name === 'match5' && isJurisdiction !== 'Mississippi')
            return false;

          ///////////////////////////////////////
          // Missouri
          ///////////////////////////////////////
          if (
            (field.name === 'cashPopPlay' ||
              field.name === 'newKenoRefresh' ||
              field.name === 'showMeCash') &&
            isJurisdiction !== 'Missouri'
          )
            return false;

          ///////////////////////////////////////
          // New Jersey
          ///////////////////////////////////////
          if (
            (field.name === 'jerseyCash5' ||
              field.name === 'njCashPop4Board' ||
              field.name === 'pick6' ||
              field.name === 'quickDrawBullseye') &&
            isJurisdiction !== 'New Jersey'
          )
            return false;

          ///////////////////////////////////////
          // New York
          ///////////////////////////////////////
          if (
            (field.name === 'numbers' ||
              field.name === 'pick10' ||
              field.name === 'quickDrawQuickDrawExtra' ||
              field.name === 'returnBags' ||
              field.name === 'take5' ||
              field.name === 'win4') &&
            isJurisdiction !== 'New York'
          )
            return false;

          ///////////////////////////////////////
          // North Carolina
          ///////////////////////////////////////
          if (
            field.name === 'ncKenoMultiplier' &&
            isJurisdiction !== 'North Carolina'
          )
            return false;

          ///////////////////////////////////////
          // South Carolina
          ///////////////////////////////////////
          if (
            field.name === 'palmettoCash5' &&
            isJurisdiction !== 'South Carolina'
          )
            return false;

          ///////////////////////////////////////
          // Tennessee
          ///////////////////////////////////////
          if (
            (field.name === 'kenoToGo' ||
              field.name === 'tnCash' ||
              field.name === 'tnDailyJackpot') &&
            isJurisdiction !== 'Tennessee'
          )
            return false;

          ///////////////////////////////////////
          // Washington
          ///////////////////////////////////////
          if (
            (field.name === 'hit5' ||
              field.name === 'instantShowcaseRolls' ||
              field.name === 'match4') &&
            isJurisdiction !== 'Washington'
          )
            return false;

          ///////////////////////////////////////
          // Wisconsin
          ///////////////////////////////////////
          if (
            (field.name === 'allOrNothing' ||
              field.name === 'badger5' ||
              field.name === 'megaBucks' ||
              field.name === 'superCash' ||
              field.name === 'thermalTicketStock') &&
            isJurisdiction !== 'Wisconsin'
          )
            return false;

          return true; // Allow other fields through
        });
      }

      return (
        <FormComponent
          formConfig={dynamicFormConfig}
          userDetails={userDetails}
          translations={translations}
          onChange={data => {
            if (data.isSecondTimeReturn !== undefined) {
              handleSecondTimeReturnChange(data.isSecondTimeReturn);
            }
            if (data.isTech !== undefined) {
              handleIsTechChange(data.isTech);
            }
            if (data.openCase !== undefined) {
              handleOpenCaseChange(data.openCase);
            }
            if (data.jurisdiction !== undefined) {
              handleIsJIDChange(data.jurisdiction);
            }
            if (data.updatePhone !== undefined) {
              handleUpdatePhone(data.updatePhone);
            }
            if (data.requestedPasscode !== undefined) {
              handleRequestedPasscode(data.requestedPasscode);
            }
            if (data.updateStoreHours !== undefined) {
              handleUpdateStoreHours(data.updateStoreHours);
            }
            if (data.supRequested !== undefined) {
              handleSupervisorRequested(data.supRequested);
            }
          }}
          onSubmit={data => {
            console.log('Form submitted:', data);
          }}
          setShowForm={setShowForm} // pass setShowForm to FormComponent
        />
      );
    } else {
      return (
        <Typography>
          {translations.selectFormToBegin || 'Select a form to begin'}
        </Typography>
      );
    }
  };

  return (
    <>
      <NavigationBar />
      <Box sx={{ flexGrow: 1, margin: '20px' }}>
        <Grid container spacing={2}>
          {isMobile && (
            <Grid item xs={12}>
              <Button
                variant='outlined'
                onClick={() => setIsMenuVisible(!isMenuVisible)}
                sx={{
                  mb: 2,
                  flex: 1,
                  backgroundColor: isMenuVisible ? '#0a417a' : '#0c51a1',
                  '&:hover': {
                    backgroundColor: isMenuVisible ? '#08305a' : '#0b4690',
                  },
                }}
              >
                {isMenuVisible
                  ? translations.hideFilters || 'Hide Filters'
                  : translations.selectFilters || 'Select Filters'}
              </Button>
            </Grid>
          )}
          {(!isMobile || isMenuVisible) && (
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 2,
                  padding: '20px',
                  height: '100%',
                  width: { xs: 'auto', md: '90vw' },
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={formMenuData(translations).map(data => ({
                      value: data.country,
                      label: data.country,
                    }))}
                    selectedValue={selectedCountry}
                    onChange={handleCountryChange}
                    placeholder={translations.selectCountry || 'Select Country'}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={teams.map(team => ({
                      value: team.name,
                      label: team.name,
                    }))}
                    selectedValue={selectedTeam}
                    onChange={handleTeamChange}
                    placeholder={translations.selectTeam || 'Select Team'}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DropDown
                    items={forms.map(form => ({
                      value: form.componentKey,
                      label: form.name,
                    }))}
                    selectedValue={selectedFormType}
                    onChange={handleFormTypeChange}
                    placeholder={
                      translations.selectFormType || 'Select Form Type'
                    }
                  />
                  <Button
                    variant='outlined'
                    onClick={handleShowFormClick}
                    sx={{ mt: 2 }}
                    disabled={!selectedFormType}
                  >
                    {translations.loadForm || 'Load Form'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 2, md: 1 }}
          sx={{ mt: { xs: '10%', md: 'auto' } }}
        >
          {renderForm()}
        </Grid>
      </Box>
    </>
  );
};

export default Forms;
