import React, { useState, useContext, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  Collapse,
} from '@mui/material';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  MenuIcon,
  CloseIcon,
  ConfirmationNumberIcon,
  LogoutIcon,
  HelpIcon,
  HomeIcon,
  HeadsetMicIcon,
  WidgetsIcon,
  DynamicFormIcon,
  AssessmentIcon,
  SettingsIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  AdminPanelSettingsIcon,
  FileOpenIcon,
  BuildCircleIcon,
  ConstructionIcon,
} from '../assets/icons/icons';

import LogoImage from '../assets/GNIECloud-Logo-500_white.png';
import useNavigateTo from '../hooks/navigation';
import useActivePage from '../hooks/activePage';
import { myMSALObj } from '../services/sso/authPopup';
import Cookies from 'js-cookie';
import useFetchProfilePhoto from '../hooks/fetchProfilePhoto';
import { LanguageContext } from '../context/languageContext';
import SettingsMenu from './settingsMenu';

const NavigationBar = ({ profilePicture }) => {
  const theme = useTheme();
  const { translations, language, changeLanguage } =
    useContext(LanguageContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigateTo = useNavigateTo();
  const { isActive } = useActivePage();
  const { ssoProfilePhotoUrl, standardProfilePhotoUrl } =
    useFetchProfilePhoto();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [helpMenuAnchor, setHelpMenuAnchor] = useState(null);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [servicesMenuAnchor, setServicesMenuAnchor] = useState(null);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
  const [themeMode, setThemeMode] = useState('system');
  const [userGroups, setUserGroups] = useState([]);
  const authEndpoint = process.env.REACT_APP_ENDPOINT_AUTH;

  // Retrieve user groups from local storage
  useEffect(() => {
    // Retrieve user groups from local storage
    const groups = JSON.parse(localStorage.getItem('userGroups')) || [];
    setUserGroups(groups);
  }, []);

  // console.log('userGroups:', userGroups);

  // Toggle the mobile drawer menu
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Open the help menu on click
  const handleHelpMenuOpen = event => {
    setHelpMenuAnchor(event.currentTarget);
  };

  // Close the help menu on click and redirect to the help URL
  const handleHelpMenuClose = (url = null) => {
    setHelpMenuAnchor(null);
    if (url) {
      window.location.href = url;
    }
  };

  // Open the services menu on click for mobile or hover for desktop
  const handleServicesMenuOpen = event => {
    // Open the services menu on click for mobile
    if (isMobile) {
      setServicesMenuOpen(!servicesMenuOpen);
    } else {
      setServicesMenuAnchor(event.currentTarget);
    }
  };

  // Close the services menu on click
  const handleServicesMenuClose = () => {
    setServicesMenuAnchor(null);
  };

  // Open the settings menu on click
  const handleSettingsMenuOpen = event => {
    setSettingsMenuAnchor(event.currentTarget);
  };

  // Close the settings menu on click or hover
  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchor(null);
  };

  // Logout the user and redirect to the login page
  const handleLogout = () => {
    const token = Cookies.get('token');
    // Redirect to login if no token is found in cookies
    if (!token) {
      console.error('No token found, redirecting to login');
      window.location.href = '/login';
      return;
    }

    // Redirect to login page after logout
    const redirectToLogin = () => {
      Cookies.remove('token'); // Remove token from cookies
      Cookies.remove('localToken'); // Remove local token from cookies
      console.log('Redirecting to login');
      window.location.href = '/login'; // Redirect to login page
    };
    // Logout the user and redirect to login page
    if (myMSALObj && myMSALObj.getAccount()) {
      myMSALObj.logout(); // Logout the user from the SSO session
      redirectToLogin(); // Redirect to login page
    } else {
      fetch(`${authEndpoint}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.ok) {
            console.log('Logout successful');
            redirectToLogin();
          } else {
            response.text().then(text => console.error('Logout failed:', text));
          }
        })
        .catch(error => {
          console.error('Logout error:', error);
        });
    }
  };

  // Get the theme based on the theme mode selected
  const getTheme = () => {
    if (themeMode === 'light') {
      return createTheme({ palette: { mode: 'light' } });
    } else if (themeMode === 'dark') {
      return createTheme({ palette: { mode: 'dark' } });
    } else {
      return theme;
    }
  };

  //  Navigation items for the sidebar
  const navigationItems = [
    {
      name: translations['home'] || 'Home',
      icon: <HomeIcon />,
      onClick: () => navigateTo('/home'),
      path: '/home',
    },
    ...(userGroups.includes('Engage_Administrators') // Check if user is an admin to display the Admin Panel
      ? [
          {
            name: translations['AdminPanel'] || 'Admin Panel',
            icon: <AdminPanelSettingsIcon />,
            onClick: () => navigateTo('/admin'),
            path: '/admin',
          },
        ]
      : []),
    {
      name: 'Repair',
      icon: <ConstructionIcon />,
      onClick: () => navigateTo('/depot'),
      path: '/depot',
    },
    {
      name: translations['contactCenter'] || 'Contact Center',
      icon: <HeadsetMicIcon />,
      onClick: () => navigateTo('/contact-center'),
      path: '/contact-center',
    },
    {
      name: translations['services'] || 'Services',
      icon: <WidgetsIcon />,
      onClick: handleServicesMenuOpen,
      subItems: [
        {
          name: translations['files'] || 'Files',
          icon: <FileOpenIcon />,
          onClick: () => navigateTo('/files'),
        },
        {
          name: translations['forms'] || 'Forms',
          icon: <DynamicFormIcon />,
          onClick: () => navigateTo('/forms'),
          path: '/forms',
        },
        {
          name: translations['reports'] || 'Reports',
          icon: <AssessmentIcon />,
          onClick: () => navigateTo('/reports'),
          path: '/reports',
        },
      ],
    },
    {
      name: translations['settings'] || 'Settings',
      icon: <SettingsIcon />,
      onClick: handleSettingsMenuOpen,
      path: '/settings',
    },
  ];

  // Render the navigation items for the sidebar
  const renderNavigationItems = () => (
    <List>
      {navigationItems.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem
            button
            onClick={item.onClick}
            sx={{
              color: isActive(item.path) ? '#ffa600' : 'inherit',
              '&:hover': {
                backgroundColor: '#0c51a1',
                color: '#FFF',
              },
              border: '1px solid rgba(255, 255, 255, 0.2)',
              margin: '5px 0',
              borderRadius: '4px',
            }}
          >
            <ListItemIcon
              sx={{ color: isActive(item.path) ? '#ffa600' : 'inherit' }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} />
            {item.subItems && (
              <ListItemIcon>
                {servicesMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
            )}
          </ListItem>
          {item.subItems && (
            <Collapse in={servicesMenuOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {item.subItems.map((subItem, subIndex) => (
                  <ListItem
                    button
                    key={subIndex}
                    onClick={subItem.onClick}
                    sx={{
                      color: isActive(subItem.path) ? '#ffa600' : 'inherit',
                      '&:hover': {
                        backgroundColor: '#0c51a1',
                        color: '#FFF',
                      },
                      border: '1px solid rgba(255, 255, 255, 0.2)',
                      margin: '5px 0',
                      borderRadius: '4px',
                      paddingLeft: theme.spacing(4),
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: isActive(subItem.path) ? '#ffa600' : 'inherit',
                      }}
                    >
                      {subItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={subItem.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
      {isMobile && (
        <>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: '#0c51a1',
                color: '#FFF',
              },
              border: '1px solid rgba(255, 255, 255, 0.2)',
              margin: '5px 0',
              borderRadius: '4px',
            }}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary={translations['logout'] || 'Logout'} />
          </ListItem>
        </>
      )}
    </List>
  );

  // Drawer component for mobile view with navigation items
  const drawer = (
    <Drawer
      anchor='left'
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 240,
          backgroundColor: '#053469',
          color: 'white',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ flexGrow: 1, padding: '0 8px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: 1,
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon sx={{ color: 'red' }} />
            </IconButton>
          </Box>
          {renderNavigationItems()}
        </Box>
        <Box sx={{ flexGrow: 0, padding: '8px' }}>
          <ListItem
            button
            onClick={() =>
              (window.location.href =
                'https://igt-apps.easyvista.com/s/ServiceDesk')
            }
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: '#0c51a1',
                color: '#FFF',
              },
              border: '1px solid rgba(255, 255, 255, 0.2)',
              margin: '5px 0',
              borderRadius: '4px',
            }}
          >
            <ListItemIcon>
              <ConfirmationNumberIcon sx={{ color: '#ffa600' }} />
            </ListItemIcon>
            <ListItemText
              primary={translations['submitTicket'] || 'Submit a Ticket'}
            />
          </ListItem>
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <ThemeProvider theme={getTheme()}>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <AppBar position='static' sx={{ backgroundColor: '#053469' }}>
          <Toolbar>
            {isMobile && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              src={LogoImage}
              alt='Logo'
              style={{ marginRight: '10px', height: '50px', cursor: 'pointer' }}
              onClick={() => navigateTo('/home')}
            />

            <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              GNIECloud Engage
            </Typography>

            <Avatar
              alt='Avatar'
              src={ssoProfilePhotoUrl || standardProfilePhotoUrl || ''}
              sx={{ width: 40, height: 40, cursor: 'pointer' }}
            />
            {isMobile ? null : (
              <>
                {navigationItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        margin: '0 8px',
                      }}
                    >
                      <IconButton
                        onClick={item.onClick}
                        style={{
                          color: isActive(item.path) ? '#ffa600' : 'white',
                        }}
                        aria-controls={
                          item.name === 'Services' ? 'services-menu' : undefined
                        }
                        aria-haspopup={
                          item.name === 'Services' ? 'true' : undefined
                        }
                      >
                        {item.icon}
                      </IconButton>
                      <Typography
                        variant='body2'
                        style={{
                          fontSize: isMobile ? 'inherit' : '0.75rem',
                          marginTop: '-6px',
                          color: isActive(item.path) ? '#ffa600' : 'inherit',
                        }}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </React.Fragment>
                ))}
                <Menu
                  id='services-menu'
                  anchorEl={servicesMenuAnchor}
                  open={Boolean(servicesMenuAnchor)}
                  onClose={handleServicesMenuClose}
                  MenuListProps={{ onMouseLeave: handleServicesMenuClose }}
                  PaperProps={{
                    style: {
                      backgroundColor: '#0c51a1',
                      color: 'white',
                    },
                  }}
                >
                  <MenuItem onClick={() => navigateTo('/files')}>
                    <FileOpenIcon sx={{ marginRight: 1 }} />
                    {translations['files'] || 'Files'}
                  </MenuItem>
                  <MenuItem onClick={() => navigateTo('/forms')}>
                    <DynamicFormIcon sx={{ marginRight: 1 }} />
                    {translations['forms'] || 'Forms'}
                  </MenuItem>
                  <MenuItem onClick={() => navigateTo('/reports')}>
                    <AssessmentIcon sx={{ marginRight: 1 }} />
                    {translations['reports'] || 'Reports'}
                  </MenuItem>
                </Menu>
                <Tooltip title='Help'>
                  <div
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={handleHelpMenuOpen}
                      style={{ color: 'white' }}
                      aria-label='help'
                    >
                      <HelpIcon />
                    </IconButton>
                    <Typography
                      variant='body2'
                      style={{
                        fontSize: isMobile ? 'inherit' : '0.75rem',
                        color: 'white',
                        marginTop: '-6px',
                      }}
                    >
                      {translations['help'] || 'Help'}
                    </Typography>
                  </div>
                </Tooltip>
                <Menu
                  id='help-menu'
                  anchorEl={helpMenuAnchor}
                  open={Boolean(helpMenuAnchor)}
                  onClose={() => setHelpMenuAnchor(null)}
                  PaperProps={{
                    style: {
                      backgroundColor: '#0c51a1',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                    },
                  }}
                >
                  <a
                    href='https://igt-apps.easyvista.com/index.php?timestamp=1617993446970&name=com.igt.5df1f9d1a49a3&page=606cd7bb217b1'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <MenuItem onClick={() => handleHelpMenuClose()}>
                      {translations['submitTicket'] || 'Submit a Ticket'}
                      <ConfirmationNumberIcon
                        sx={{ ml: 2, color: '#ffa600' }}
                      />
                    </MenuItem>
                  </a>
                </Menu>
                <SettingsMenu
                  settingsMenuAnchor={settingsMenuAnchor}
                  settingsMenuOpen={Boolean(settingsMenuAnchor)}
                  handleSettingsMenuClose={handleSettingsMenuClose}
                  themeMode={themeMode}
                  handleThemeChange={event => setThemeMode(event.target.value)}
                  language={language}
                  handleLanguageChange={event =>
                    changeLanguage(event.target.value)
                  }
                />
              </>
            )}
            {!isMobile && (
              <Tooltip title='Logout'>
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    color='inherit'
                    onClick={handleLogout}
                    sx={{ color: 'white' }}
                  >
                    <LogoutIcon sx={{ ml: 2 }} />
                  </IconButton>
                  <Typography
                    variant='body2'
                    style={{
                      fontSize: isMobile ? 'inherit' : '0.75rem',
                      color: 'white',
                      marginTop: '-6px',
                      marginLeft: '5px',
                    }}
                  >
                    {translations['logout'] || 'Logout'}
                  </Typography>
                </div>
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
        {isMobile && drawer}
      </Box>
      <SettingsMenu
        settingsMenuAnchor={settingsMenuAnchor}
        settingsMenuOpen={Boolean(settingsMenuAnchor)}
        handleSettingsMenuClose={handleSettingsMenuClose}
        themeMode={themeMode}
        handleThemeChange={event => setThemeMode(event.target.value)}
        language={language}
        handleLanguageChange={event => changeLanguage(event.target.value)}
        isMobile={isMobile}
      />
    </ThemeProvider>
  );
};

export default NavigationBar;
